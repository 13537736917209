@layer base {
  :root {
    /* #d4021d */
    --primary: 212 2 29;
    --primary-dark: #960014;
    --primary-light: #f33f57;
    --primary-texttransparent: #ffffff99;

    /* #ff0000 */
    --secondary: 255 0 0;
    --secondary-dark: #9f0000;
    --secondary-light: #ff3f3f;
    --secondary-constrasttext: #ffffff;

    --sustainability-constrasttext: #ffffff;

    --sale: #f00020;

    --grey: #e6e6e6;
    --grey-light: rgb(246, 246, 246);
    --grey-light-dark: rgb(230, 230, 230);
    --grey-dark: #777777;

    --linear-progress-background: #f99eaa;

    --text-disabled: #4e5051;
    --text-primary: #222222;
    --text-secondary: #22222299;
    --text-dark: #222222;
    --text-darktransparent: #222222a6;
    --text-light: #ffffff;
    --text-lightTransparent: #ffffffa6;

    --border-sm: 3px;
    --border-default: 3px;
    --border-md: 12px;
    --border-lg: 16px;
    --border-xl: 20px;
  }
}

@layer components {
  .price-old-price {
    text-decoration: none;
  }

  .page-logo {
    @apply w-[75px] xl:w-[143px];
  }
  .scroll-to-top-button {
    bottom: 5rem;
    }

  .button {
    @apply rounded;
  }

  .pagination-item {
    @apply rounded;
  }

  .toggle-group-item {
    @apply first:rounded-l last:rounded-r;
  }
  
  .info-chip-filled {
    @apply text-color-info-dark hover:text-color-text-dark border-color-info-dark bg-color-info-light;
  }

  .info-chip-filled-selected {
    @apply text-color-text-dark border-color-info bg-color-info-dark;
  }
}
